.hljs {
  display: block;
}
.hljs,
.hljs-subst {
  color: #444;
}
.hljs-comment {
  color: #888;
}
.hljs-doctag,
.hljs-keyword,
.hljs-meta-keyword,
.hljs-name,
.hljs-selector-tag {
  font-weight: 700;
}
.hljs-attribute {
  color: #0e9a00;
}
.hljs-function {
  color: #99069a;
}
.hljs-builtin-name {
  color: #99069a;
}
.hljs-deletion,
.hljs-number,
.hljs-quote,
.hljs-selector-class,
.hljs-selector-id,
.hljs-string,
.hljs-template-tag,
.hljs-type {
  color: #800;
}
.hljs-section,
.hljs-title {
  color: #800;
  font-weight: 700;
}
.hljs-link,
.hljs-regexp,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-symbol,
.hljs-template-variable,
.hljs-variable {
  color: #bc6060;
}
.hljs-literal {
  color: #78a960;
}
.hljs-addition,
.hljs-built_in,
.hljs-bullet,
.hljs-code {
  color: #0c9a9a;
}
.hljs-meta {
  color: #1f7199;
}
.hljs-meta-string {
  color: #4d99bf;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}

@media (prefers-color-scheme: dark) {
  .hljs,
  .hljs-subst {
    color: #ccc;
  }
}
