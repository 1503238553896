body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bg-blue {
  background-color: rgb(0, 122, 255);
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: #090909;
    color: #eee;
  }
  .black {
    color: #eee;
  }
  .bg-white {
    background-color: #090909;
  }
  .bg-near-white {
    background-color: #111;
  }
  .b--light-gray {
    border-color: #222;
  }
  .b--moon-gray {
    border-color: #333;
  }
}
